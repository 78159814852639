const plugin = require('tailwindcss/plugin')

module.exports = function() {
  return plugin(({ theme, addComponents }) => {
    const newComponents = {
      '.wrapper': {
        maxWidth: `${theme('screens.xl')}`,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [`@media (min-width: ${theme('screens.md')})`]: {
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        },
      },
    }

    addComponents(newComponents)
  })
}
