export const getRealIndex = (index, num) => {
  return index < 0 ? num-1
    : (index > num-1) ? 0
    : index
}

export const getShortMonth = (num) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return months[num - 1];
}
