const plugin = require('tailwindcss/plugin')

module.exports = function() {
  return plugin(({ theme, addUtilities }) => {
    const prefix = '.type-'
    const jpSuffix = '-jp'

    const textStyles = {
      // - 10, weight 500, tracking 1.75
      // Label
      [`${prefix}050-sans`]: {
        fontFamily: theme('fontFamily.sans'),
        fontSize: theme('fontSize.000'),
        fontWeight: theme('fontWeight.medium'),
        letterSpacing: theme('letterSpacing.widest'),
        textTransform: 'uppercase',
      },
      // - 12, weight 600, tracking 2.88
      // Caption
      [`${prefix}160`]: {
        fontSize: '0.75rem',
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 14, weight 300, tracking 0.25
      // Body Small EN
      [`${prefix}230`]: {
        fontSize: theme('fontSize.200'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 16, weight 300, tracking 0.25
      // Body Small EN
      [`${prefix}330`]: {
        fontSize: theme('fontSize.300'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 16, weight 600, tracking 0.25
      // Body Small Heavy EN
      [`${prefix}360`]: {
        fontSize: theme('fontSize.300'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 18, weight 600, tracking 0.25
      // Body Medium Light EN
      [`${prefix}430`]: {
        fontSize: theme('fontSize.400'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 18, weight 600, tracking 0.25
      // Body Medium EN
      [`${prefix}460`]: {
        fontSize: theme('fontSize.400'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 20, weight 300, tracking 0.25
      // Body Large Light
      [`${prefix}530`]: {
        fontSize: theme('fontSize.500'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 20, weight 600, tracking 0.25
      // Body Large Light
      [`${prefix}560`]: {
        fontSize: theme('fontSize.500'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 24, weight 600, tracking 0.25
      // Headline 5
      [`${prefix}660`]: {
        fontSize: theme('fontSize.600'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 32, weight 600, tracking 0.5
      // Headline 4
      [`${prefix}760`]: {
        fontSize: theme('fontSize.700'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.wide'),
      },
      // - 48, weight 600, tracking 0.5
      // Headline 3
      [`${prefix}860`]: {
        fontSize: theme('fontSize.800'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.wide'),
      },
      // - 56, weight 600, tracking 1
      // Headline 2
      [`${prefix}960`]: {
        fontSize: theme('fontSize.900'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.wider'),
      },
      // - 82, weight 600, tracking 1.75
      // Headline 1
      [`${prefix}1060`]: {
        fontSize: theme('fontSize.1000'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.widest'),
      },
      // - 108, weight 600, tracking 1.75
      // Headline 1
      [`${prefix}1160`]: {
        fontSize: theme('fontSize.1100'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.widest'),
      },
    }

    const textStylesJP = {
      // - 10, weight 500, tracking 1.75
      // Label
      [`${prefix}050-sans${jpSuffix}`]: {
        fontFamily: theme('fontFamily.sans'),
        fontSize: theme('fontSize.000'),
        fontWeight: theme('fontWeight.medium'),
        letterSpacing: theme('letterSpacing.widest'),
        textTransform: 'uppercase',
      },
      // - 12, weight 600, tracking 2.88
      // Caption
      [`${prefix}160${jpSuffix}`]: {
        fontSize: '0.75rem',
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 14, weight 300, tracking 0.25
      // Body Small JP
      [`${prefix}230${jpSuffix}`]: {
        fontSize: theme('fontSize.200'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 14, weight 600, tracking 0.25
      // Body Small Heavy JP
      [`${prefix}260${jpSuffix}`]: {
        fontSize: theme('fontSize.200'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 16, weight 300, tracking 0.25
      // Body Medium Light JP
      [`${prefix}330${jpSuffix}`]: {
        fontSize: theme('fontSize.300'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 16, weight 600, tracking 0.25
      // Body Medium JP
      [`${prefix}360${jpSuffix}`]: {
        fontSize: theme('fontSize.300'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 20, weight 300, tracking 0.25
      // Body Large Light JP
      [`${prefix}530${jpSuffix}`]: {
        fontSize: theme('fontSize.500'),
        fontWeight: theme('fontWeight.light'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 20, weight 600, tracking 0.25
      // Body Large JP
      [`${prefix}560${jpSuffix}`]: {
        fontSize: theme('fontSize.500'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 24, weight 600, tracking 0.25
      // Headline 5
      [`${prefix}660${jpSuffix}`]: {
        fontSize: theme('fontSize.600'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.gap'),
      },
      // - 32, weight 600, tracking 0.5
      // Headline 4
      [`${prefix}760${jpSuffix}`]: {
        fontSize: theme('fontSize.700'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.wide'),
      },
      // - 48, weight 600, tracking 0.5
      // Headline 3
      [`${prefix}860${jpSuffix}`]: {
        fontSize: theme('fontSize.800'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.wide'),
      },
      // - 56, weight 600, tracking 1
      // Headline 2
      [`${prefix}960${jpSuffix}`]: {
        fontSize: theme('fontSize.900'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.wider'),
      },
      // - 82, weight 600, tracking 1.75
      // Headline 1
      [`${prefix}1060${jpSuffix}`]: {
        fontSize: theme('fontSize.1000'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.widest'),
      },
      // - 108, weight 600, tracking 1.75
      // Headline 1
      [`${prefix}1160${jpSuffix}`]: {
        fontSize: theme('fontSize.1100'),
        fontWeight: theme('fontWeight.semibold'),
        letterSpacing: theme('letterSpacing.widest'),
      },
    }

    addUtilities([textStyles, textStylesJP], ['responsive'])
  })
}
