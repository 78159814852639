import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import SEO from '@components/seo'
import HomeHero from '@components/HomeHero'
import FeaturedTattoo from '@components/FeaturedTattoo'
import About from '@components/About'
import LatestNews from '@components/LatestNews'
import InstagramPosts from '@components/InstagramPosts'
import localize from '@components/localize'

// export const query = graphql`
//   query {
//     sanityHomePage {
//       about {
//         aboutDescription {
//           _rawEn
//           _rawJp
//         }
//       }
//       news {
//         latestNewsLink {
//           ... on SanityExternalLink {
//             _key
//             _type
//             label {
//               en
//               jp
//             }
//             url
//           }
//           ... on SanityInternalLink {
//             _key
//             _type
//             label {
//               en
//               jp
//             }
//             page {
//               ... on SanityPage {
//                 slug {
//                   current
//                 }
//               }
//               ... on SanityTattooCategory {
//                 slug {
//                   current
//                 }
//               }
//             }
//           }
//         }
//         latestNewsImage {
//           asset {
//             fluid(maxWidth: 500) {
//               ...GatsbySanityImageFluid_withWebp
//             }
//           }
//         }
//         latestNewsDescription {
//           _rawJp
//           _rawEn
//         }
//       }
//       instaImages {
//         asset {
//           id
//           fluid(maxWidth: 720) {
//             ...GatsbySanityImageFluid_withWebp
//           }
//         }
//       }
//     }
//   }
// `

export const query = graphql`
  query {
    allSanityHomePage {
      edges {
        node {
          about {
            aboutDescription {
              _rawEn(resolveReferences: {maxDepth: 10})
              _rawJp(resolveReferences: {maxDepth: 10})
            }
          }
          news {
            latestNewsDescription {
              _rawEn(resolveReferences: {maxDepth: 10})
              _rawJp(resolveReferences: {maxDepth: 10})
            }
            latestNewsImage {
              asset {
                fluid(maxWidth: 500) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            latestNewsLink {
              ... on SanityExternalLink {
                _key
                _type
                label {
                  en
                  jp
                }
                url
              }
              ... on SanityInternalLink {
                _key
                _type
                label {
                  en
                  jp
                }
                page {
                  ... on SanityTattooCategory {
                    id
                    slug {
                      current
                    }
                  }
                  ... on SanityPage {
                    id
                    slug {
                      current
                    }
                  }
                }
              }
            }
          }
          instaImages {
            asset {
              id
              fluid(maxWidth: 720) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const HomePageTemplate = ({
  data: { allSanityHomePage },
  pageContext: { title, description, metaImage},
  delay=8000,
}) => (
    <Layout headerFadeIn delay={delay}>
      <SEO description={description} image={metaImage?.asset.url} />
      <HomeHero delay={delay} />
      <FeaturedTattoo />
      <About data={allSanityHomePage.edges[0].node.about} />
      <LatestNews data={allSanityHomePage.edges[0].node.news} />
      <InstagramPosts images={allSanityHomePage.edges[0].node.instaImages} />
    </Layout>
)

HomePageTemplate.propTypes = {
  data: PropTypes.shape({
    sanityHomePage: PropTypes.shape({
      news: PropTypes.object,
    }),
  })
}

HomePageTemplate.defaultProps = {
  data: {
    sanityHomePage: {}
  }
}

export default localize(HomePageTemplate);
