import React, { useRef, useEffect } from 'react'
import cn from 'classnames'
import Swiper from 'swiper'
import Img from 'gatsby-image'
import InViewport from '@components/InViewport'
import Link from '@utility/Link'
import Icon from '@utility/Icon'
import { theme } from '@root/tailwind.config.js'
import styles from './styles'

const InstagramPosts = ({ images }) => {

  const target = useRef(null)
  const swiper = useRef(null)

  const params = {
    slidesPerView: 1.5,
    freeMode: true,
    freeModeMomentum: false,
    freeModeMomentumBounce: false,
    breakpoints: {
      [parseInt(theme.screens.sm)] : {
        slidesPerView: 2.5,
      }
    }
  }

  const swiperResponsiveController = media => {
    media.matches ?
      swiper.current && swiper.current.destroy() :
      swiper.current = new Swiper(target.current, params)
  }

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${theme.screens.lg})`)
    const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    swiperResponsiveController(media)
    is_safari ? media.addListener(swiperResponsiveController) : media.addEventListener("change", swiperResponsiveController)

    return () => is_safari ? media.removeListener(swiperResponsiveController) : media.removeEventListener("change", swiperResponsiveController)
  })

  return (
    <InViewport
      threshold={0.4}
    >
      <div ref={target} className={cn(styles.container, "swiper-container")}>
        <div slot="container-start" className={styles.title}>
          <Icon name="instagram" color="white" className="w-4 mr-2 inline-block" /><span className="align-middle">Instagram</span>
        </div>
        <div className={cn(styles.wrapper, "swiper-wrapper")}>
          {images && images.map((image, index) => (
            <Link
              to={`https://www.instagram.com/shige_yellowblaze`}
              key={`${image.asset.id}-${index}`}
              className={cn(styles.slide, "swiper-slide")}
            >
              <Img
                fluid={image.asset.fluid}
                className={styles.img}
                placeholderClassName="gatsby-image-placeholder"
              />
            </Link>
          ))}
        </div>
      </div>
    </InViewport>
  )
}

export default InstagramPosts
