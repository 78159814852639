import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import cn from 'classnames'
import LocaleContext from '@context/LocaleContext'
import InViewport from '@components/InViewport'
import TitleVertical from '@components/TitleVertical'
import RichText from '@components/RichText'
import styles from './styles'

const About = ({
  data: { aboutDescription }
}) => {
  const { code } = useContext(LocaleContext)
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "skull-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <InViewport
      className={styles.container}
      threshold={0.5}
    >
      <div className="row sm:flex-row-reverse">
        <div className="col-12 sm:col-4">
          <div className={styles.title}>
            <TitleVertical
              title={{
                jp: "願い",
                en: "Wish"
              }}
              index={2}
              white
            />
          </div>
        </div>
        <div className="col-12 sm:col-4">
          <RichText
            className={cn(styles.text, {[styles.jp]: code === 'jp'})}
            localeBlocks={aboutDescription}
            lang={code}
            styled={false}
          />
        </div>
      </div>
      <div className={styles.bg}>
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={styles.img}
          alt="Skull Logo"
          placeholderClassName="gatsby-image-placeholder"
        />
      </div>
    </InViewport>
  )
}

export default About
