/*
|-----------------------------------------------------------------------------
| Grid Plugin       // Base: https://github.com/shaneoliver/tailwind-flex-grid
|-----------------------------------------------------------------------------
|
| This will allow dynamic gutter setting depending on breakpoints.
| Usage is pretty much the same as originals of the plugin,
| but differnce is that column gutter changes depending on window size.
|
| Example:
| <div class="container">
|   <div class="row">
|     <div class="col-24 md:offset-4 md:col-16">{ content }</div>
|   </div>
| </div>
|
*/

const _ = require('lodash')

function getOffset(size, columns) {
  const num = size / _.max(columns);
  if (num === 1) {
    return '0';
  }
  return `${(size / _.max(columns)) * 100}%`;
}

module.exports = function gridGenerator({
  columns = _.range(0, 12),
  gutters,
  breakpoints,
  maxWidth,
}) {
  return function flexGrid({ addComponents }) {
    const flexRules = [
      {
        '.row': {
          display: 'flex',
          flexWrap: 'wrap',
          marginLeft: `${gutters[0] / -2}px`,
          marginRight: `${gutters[0] / -2}px`,
          [`@media (min-width: ${breakpoints[0]}px)`]: {
            marginRight: `${gutters[1] / -2}px`,
            marginLeft: `${gutters[1] / -2}px`,
          },
          [`@media (min-width: ${breakpoints[1]}px)`]: {
            marginRight: `${gutters[2] / -2}px`,
            marginLeft: `${gutters[2] / -2}px`,
          },
        },
      },

      {
        '.col': {
          flexBasis: 0,
          flexGrow: 1,
          maxWidth: '100%',
          paddingRight: `${gutters[0] / 2}px`,
          paddingLeft: `${gutters[0] / 2}px`,
          [`@media (min-width: ${breakpoints[0]}px)`]: {
            paddingRight: `${gutters[1] / 2}px`,
            paddingLeft: `${gutters[1] / 2}px`,
          },
          [`@media (min-width: ${breakpoints[1]}px)`]: {
            paddingRight: `${gutters[2] / 2}px`,
            paddingLeft: `${gutters[2] / 2}px`,
          },
        },
      },

      ...columns.map((size) => ({
        [`.col-${size}`]: {
          position: 'relative',
          width: '100%',
          minHeight: '1px',
          paddingRight: `${gutters[0] / 2}px`,
          paddingLeft: `${gutters[0] / 2}px`,
          flex: `0 0 ${(size / _.max(columns)) * 100}%`,
          maxWidth: `${(size / _.max(columns)) * 100}%`,
          [`@media (min-width: ${breakpoints[0]}px)`]: {
            paddingRight: `${gutters[1] / 2}px`,
            paddingLeft: `${gutters[1] / 2}px`,
          },
          [`@media (min-width: ${breakpoints[1]}px)`]: {
            paddingRight: `${gutters[2] / 2}px`,
            paddingLeft: `${gutters[2] / 2}px`,
          },
        },
      })),

      ...columns.map((size) => ({
        [`.offset-${size}`]: {
          marginLeft: getOffset(size, columns),
        },
      })),
    ];

    addComponents({ '@variants responsive': flexRules });
  };
};
