import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import cn from 'classnames'
import Img from 'gatsby-image'
import { Transition } from 'react-transition-group'
import InViewport from '@components/InViewport'
import Icon from '@utility/Icon'
// import PreloadImages from '@utility/PreloadImages'
import { theme } from '@root/tailwind.config.js'
import styles from './styles'

const HomeHero = ({ delay }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      sanityHomePage {
        hero {
          heroImageColor {
            asset {
              fluid(maxWidth: 1440) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          heroImageColorMobile {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          heroImageMono {
            asset {
              fluid(maxWidth: 1440) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          heroImageMonoMobile {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  const [showTitle, setShowTitle] = useState(false)

  useEffect(() => { setShowTitle(true) }, [showTitle])

  return (
    <>
      {/* TODO: Figure out safari support issue */}
      {/* <PreloadImages
        images={[
          data.sanityHomePage.hero.heroImageMono.asset.fluid,
          data.sanityHomePage.hero.heroImageMonoMobile.asset.fluid,
          data.sanityHomePage.hero.heroImageColor.asset.fluid,
          data.sanityHomePage.hero.heroImageColorMobile.asset.fluid
        ]}
      /> */}
      <InViewport
        className={styles.hero}
        activeClass={styles.active}
      >
        <Transition
          timeout={delay}
          in={showTitle}
        >
          {state => (
            <>
              <h1 className={cn(styles.title, {[styles.hidden]: state === 'entered'})}>
                {!!data.site.siteMetadata.title.length ? data.site.siteMetadata.title : "YELLOW BLAZE TATTOO"}
              </h1>
              <div className={cn(styles.scroll, {[styles.appear]: state === 'entered'})}>
                <span><Icon name="caret" color="white" className="w-4 mt-1 mx-auto opacity-50" /></span>
              </div>
            </>
          )}
        </Transition>
        <Img
          fluid={[
            data.sanityHomePage.hero.heroImageMonoMobile.asset.fluid,
            {
              ...data.sanityHomePage.hero.heroImageMono.asset.fluid,
              media: `(min-width: ${theme.screens.md})`
            }
          ]}
          className={styles.mono}
          objectFit="cover"
          loading="eager"
          placeholderClassName="gatsby-image-placeholder"
          alt="Hero Image Mono"
        />
        <Img
          fluid={[
            data.sanityHomePage.hero.heroImageColorMobile.asset.fluid,
            {
              ...data.sanityHomePage.hero.heroImageColor.asset.fluid,
              media: `(min-width: ${theme.screens.md})`
            }
          ]}
          className={styles.color}
          objectFit="cover"
          style={{ position: "absolute" }}
          loading="eager"
          placeholderClassName="gatsby-image-placeholder"
          alt="Hero Image"
        />
      </InViewport>
    </>
  )
}

HomeHero.propTypes = {
  delay: PropTypes.number,
}
HomeHero.defaultProps = {
  delay: 0,
}

export default HomeHero
