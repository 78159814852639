const tailwindTypography = require('./src/utility/tailwind/typography')
const tailwindGrid = require('./src/utility/tailwind/grid')
const tailwindUtilities = require('./src/utility/tailwind/utilities')

module.exports = {
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
  purge: {
    // layer: ["utilities"], // Uncomment this if only purging 'utilities'
    content: ["./src/**/*.js", "./src/**/*.jsx", "./src/**/*.ts", "./src/**/*.tsx"],
  },
  theme: {
    colors: {
      black: {
        default: '#000',
      },
      red: {
        default: '#F00',
      },
      grey: {
        dark: '#333',
        default: '#999',
      },
      white: {
        default: '#FFF',
      },
      transparent: 'transparent'
    },
    screens: {
      sm: '640px',
      // 'sm-down': { 'max': '640px' },
      md: '768px',
      'md-down': { 'max': '768px' },
      lg: '1024px',
      // 'lg-down': { 'max': '1024px' },
      xl: '1280px',
    },
    fontFamily: {
      serif: [
        'ヒラギノ明朝 ProN',
        'Hiragino Mincho ProN',
        'Yu Mincho Light',
        'YuMincho',
        'Yu Mincho',
        '游明朝体',
        'ＭＳ Ｐ明朝',
        'MS PMincho',
        'Georgia',
        'Cambria',
        '"Times New Roman"',
        'Times',
        'serif'
      ],
      sans: [
        'Hiragino Kaku Gothic ProN',
        'ヒラギノ角ゴ ProN W3',
        'YuGothic',
        'Yu Gothic',
        'ＭＳ Ｐゴシック',
        'MS PGothic',
        "Helvetica Neue",
        'Arial',
        'sans-serif'
      ],
    },
    fontSize: {
      'reset': '0px',      //0px
      '000': '0.625rem',   //10px
      '100': '0.75rem',    //12px
      '200': '0.875rem',   //14px
      '300': '1rem',       //16px
      '400': '1.125rem',   //18px
      '500': '1.25rem',    //20px
      '600': '1.5rem',     //24px
      '700': '2rem',       //32px
      '800': '3rem',       //48px
      '900': '3.5rem',     //56px
      '1000': '5.125rem',    //82px
      '1100': '6.75rem'    //108px
    },
    fontWeight: {
      light: '300',
      medium: '500',
      semibold: '600',
    },
    lineHeight: {
      none: '1',
      tight: 1.25,
      fitted: 1.333,
      snug: 1.5,
      normal: 1.625,
      relaxed: 1.72,
      loose: 2,
    },
    letterSpacing: {
      normal: '0',
      gap: '0.015625rem', // 0.25px
      wide: '0.03125rem', // 0.5px
      wider: '0.0625rem', // 1px
      widest: '0.109375rem', // 1.75px
    },
    extend: {
      fill: theme => ({
        'black': theme('colors.black.default'),
        'white': theme('colors.white.default'),
        'grey': theme('colors.grey.default'),
      }),
      spacing: {
        '7': '1.75rem', // 28px
        '14': '3.5rem', // 56px
        '15': '3.75rem', // 60px
        '18': '4.5rem', // 72px
        '22': '5.5rem', // 88px
        '25': '6.25rem', // 100px
        '26': '6.5rem', // 104px
        '28': '7rem', // 112px
        '34': '8.5rem', // 136px
        '37': '9.25rem', // 148px
        '42': '10.5rem', // 168px
      },
      transitionProperty: {
        'width': 'width',
      },
      transitionTimingFunction: {
        'out-expo': 'cubic-bezier(.165,.84,.44,1)',
        'out-mild': 'cubic-bezier(.25,.46,.45,.94)'
      },
      zIndex: {
        '-1': '-1',
        1: '1',
      }
    }
  },
  variants: {},
  plugins: [
    tailwindTypography(),
    tailwindGrid({
      columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      gutters: [16, 24, 32],
      breakpoints: [768, 1024],
      maxWidth: 1280,
    }),
    tailwindUtilities(),
  ],
}
