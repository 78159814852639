import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import cn from 'classnames'
import Img from 'gatsby-image'
import LocaleContext from '@context/LocaleContext'
import Link from '@utility/Link'
import Icon from '@utility/Icon'
import { getRealIndex } from '@helpers/utils'
import InViewport from '@components/InViewport'
import TitleVertical from '@components/TitleVertical'
import CarouselFade from '@components/CarouselFade'
import { theme } from '@root/tailwind.config.js'
import styles from './styles'

SwiperCore.use([Pagination])

const FeaturedTattoo = () => {
  const { code } = useContext(LocaleContext)
  const [active, setActive] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      allSanityHomePage(
        limit: 5,
        sort: {order: DESC, fields: _createdAt}
      ) {
        edges {
          node {
            tattoos {
              featuredTattooImages {
                asset {
                  id
                  fluid(maxWidth: 450) {
                    ...GatsbySanityImageFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const slides = data.allSanityHomePage.edges[0].node.tattoos.featuredTattooImages

  const params = {
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    pagination: {
      el: `.${styles.pagination}`,
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      [parseInt(theme.screens.sm)]: {
        slidesPerView: 2,
      },
    }
  }

  return (
    <InViewport
      className={styles.container}
      threshold={0.4}
    >
      <div className={styles.title}>
        <TitleVertical
          title={{
            jp: "刺青",
            en: "Tattoo"
          }}
          index={1}
        />
        <div className={cn(styles.buttons, "hidden lg:block lg:mt-32")}>
          <Icon
            name="arrow"
            color="black"
            className={cn(styles.buttonPrev)}
            onClick={() => { setActive(getRealIndex(active-1, slides.length)) }}
          />
          <Icon
            name="arrow"
            color="black"
            className={cn(styles.buttonNext)}
            onClick={() => { setActive(getRealIndex(active+1, slides.length)) }}/>
        </div>
      </div>
      <Swiper
        {...params}
        className={cn(styles.swiper, "lg:hidden")}
      >
        {slides.map((img, index) => (
          <SwiperSlide
            key={`${img.asset.id}-${index}`}
            className={cn(styles.slide, "swiper-slide")}
          >
            <Img
              fluid={img.asset.fluid}
              className={styles.image}
              placeholderClassName="gatsby-image-placeholder"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <CarouselFade
        slides={slides}
        activeIdx={active}
        className={cn(styles.carousel, "hidden lg:block")}
      />
      <div className="flex justify-between mt-14 lg:mt-0">
        <div className={cn(styles.pagination, "lg:hidden")}></div>
        <Link
          to="/tattoos"
          className={styles.cta}
          styled
        >{ code === "jp" ? "刺青一覧" : "All Tattoos" }</Link>
      </div>
    </InViewport>
  )
}

export default FeaturedTattoo
