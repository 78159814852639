import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import LocaleContext from '@context/LocaleContext'
import styles from './styles'

const TitleVertical = ({
  title,
  index,
  className,
  white,
  ...rest
}) => {
  const { code } = useContext(LocaleContext)

  return (
    <div
      className={cn(className, styles.container, {[styles.white]: white})}
      {...rest}
    >
      <h2 className={styles.title}>
        { code === 'jp' ? title.jp : title.en }
      </h2>
      <span className={styles.index}>{("0" + index).slice(-2)}</span>
    </div>
  )
}

TitleVertical.propTypes = {
  title: PropTypes.shape({
    jp: PropTypes.string,
    en: PropTypes.string
  }).isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  white: PropTypes.bool,
}

TitleVertical.defaultProps = {
  index: 1,
  className: '',
  white: false,
}

export default TitleVertical
