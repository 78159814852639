import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Img from 'gatsby-image'
import { Transition } from 'react-transition-group'
import { getRealIndex } from '@helpers/utils'
import styles from './styles'

const CarouselFade = ({
  slides,
  activeIdx,
  className,
  ...rest
}) => {
  const [active, setActive] = useState(activeIdx)
  const [show, setShow] = useState(true)

  useEffect(() => setShow(false), [activeIdx])

  const callbacks = {
    onEnter: () => setActive(activeIdx),
    onExited: () => setShow(true)
  }

  const SlideImages = ({
    idxOffset = 0,
    duration = 700,
  }) => {
    return (
      <>
        <Transition
            in={show}
            timeout={{ exit: duration, enter: 1 }}
            {...callbacks}
          >
          {state => (
            <Img
              fluid={slides[getRealIndex(active+idxOffset, slides.length)].asset.fluid}
              className={cn(styles.image__real, state)}
              placeholderClassName="gatsby-image-placeholder"
            />
          )}
        </Transition>
        <Transition
            in={!show}
            timeout={{ exit: 1, enter: duration }}
          >
          {state => (
            <Img
              fluid={slides[getRealIndex(activeIdx+idxOffset, slides.length)].asset.fluid}
              className={cn(styles.image__fake, state)}
              style={{ position: 'absolute' }}
              placeholderClassName="gatsby-image-placeholder"
            />
          )}
        </Transition>
      </>
    )
  }

  return (
    <div
      className={className}
      {...rest}
    >
      <div className={styles.container}>
        <div className={cn(styles.slide, styles.prev)}>
          {SlideImages({ idxOffset: -1 })}
        </div>
        <div className={cn(styles.slide, styles.active)}>
          {SlideImages({ idxOffset: 0 })}
        </div>
        <div className={cn(styles.slide, styles.next)}>
          {SlideImages({ idxOffset: 1 })}
        </div>
      </div>
    </div>
  )
}

CarouselFade.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeIdx: PropTypes.number,
  className: PropTypes.string
}

CarouselFade.defaultProps = {
  activeIdx: 1,
  className: ''
}

export default CarouselFade
